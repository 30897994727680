<template>
    <form class="form form__login" v-if="!auth" @submit.prevent="onLogin">
        <h4 class="form-title form__login-title">Авторизация</h4>
        <div class="form-inputs">
            <input
                    type="text"
                    class="form-input form-email form__login-email"
                    placeholder="Введите логин"
                    autocomplete="off"
                    v-model="email"
            >
            <input
                    type="password"
                    class="form-input form-password form__login-password"
                    placeholder="Введите пароль"
                    autocomplete="off"
                    v-model="password"
            >
            <button
                    :disabled="!isValid"
                    class="btn indigo form__login-password"
            >Войти</button>
        </div>
    </form>
</template>

<script>
    export default {
        data() {
            return {
                email: '',
                password: ''
            }
        },
        computed: {
            auth () {
                return this.$store.getters['auth/isAuthenticated']
            },
            isValid() {
                return this.email.length >= 6 && this.password.length >= 4
            }
        },
        methods: {
            onLogin () {
                const user = {
                    email: this.email,
                    password: this.password
                }

                this.$store.dispatch('auth/login', user)
                    .then(() => {
                        if(this.auth === true) {
                            this.$router.push('/')
                        }
                    })
                    .catch(() => {})
            },
        }
    }
</script>

<style>

</style>
